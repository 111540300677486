import { useState, useEffect } from "react";

export const useMediaMatches = (mql) => {
  const [isMediaMatching, setIsMediaMatching] = useState(false);
  const mediaQueryList = window.matchMedia(mql);

  const handleScreenChange = (e) => {
    setIsMediaMatching(e.matches);
  };

  useEffect(() => {
    mediaQueryList.addEventListener("change", handleScreenChange);
    handleScreenChange(mediaQueryList);

    return () => {
      mediaQueryList.removeEventListener("change", handleScreenChange);
    };
  }, [mediaQueryList]);

  return isMediaMatching;
};

export const useMobileMediaQuery = () => useMediaMatches("(max-width:1400px)");
