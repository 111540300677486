import "./css/App.css";
import "./css/mobile.css";
import React, { useState } from "react";
import Header from "./components/Header";
import MainContainer from "./components/MainContainer";
import Footer from "./components/Footer";
import TokenModal from "./components/TokenModal";
import { useMobileMediaQuery } from "./useMediaMatches";
import MobileContainer from "./components/MobileContainer";

const App = () => {
  const isMobile = useMobileMediaQuery();
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div className="app">
      <Header />
      {isMobile ? (
        <MobileContainer onOpen={openModal} />
      ) : (
        <MainContainer onOpen={openModal} />
      )}
      <Footer />
      {open && <TokenModal onClose={closeModal} />}
    </div>
  );
};

export default App;
