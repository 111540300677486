import React from "react";
import bannerButton from "../img/banner-button.svg";
import bannerLogo1 from "../img/mb-banner-6.png";
import bannerBg2 from "../img/mb-banner-2.png";
import bannerTitle2 from "../img/second-banner-title.svg";
import bannerTitle3 from "../img/four-banner-title.svg";
import bannerChart from "../img/banner-chart.png";
import bannerTitle4 from "../img/banner-title-1.svg";
import telegramIcon from "../img/telegram.png";
import twitterIcon from "../img/twitter.png";
import bannerTitle from "../img/mb-banner-5.png";
import bannerBg3 from "../img/mb-banner-4.png";

const MobileContainer = ({ onOpen }) => {
  return (
    <div className="main-container" id="home">
      <div className="mb-first-banner">
        <div className="banner-title">
          <img src={bannerTitle} className="banner-title-img" alt="" />
        </div>
        <div className="banner-text-container">
          <div className="banner-bg">
            <img src={bannerLogo1} alt="" />
          </div>
          <div className="banner-text">
            <p>
              What does "MEME" signify? MEME embodies the collective beliefs of
              Web3 builders. The enthusiasm for consensus and innovation is
              palpable among all. However, current meme projects seem confined
              to one side of the Web3 world– a place that's rife with imitation
              and plagiarism, resulting in negligible distinction between
              different projects. This is not the environment we envision. We
              need to make some difference, united we stand, revolutionary we
              live, creative we shiny. Hence, we created "WEWE".
            </p>
          </div>
        </div>
        <div className="banner-logo-container">
          <img src={bannerBg3} alt="" />
        </div>
        <div className="button-container">
          <div className="banner-button">
            <a href="/">
              <img src={bannerButton} alt="buy" className="button-bg" />
              <span className="button-text">Buy</span>
            </a>
          </div>
          <div className="banner-token-button" onClick={onOpen}>
            Token Address
          </div>
        </div>
      </div>
      <div className="mb-second-banner" id="background">
        <div className="banner-title">
          <img src={bannerTitle2} alt="background" />
        </div>
        <div className="banner-main">
          <div className="banner-text">
            WEWE flips the expression of MEME, venturing into the other side of
            the Web3 universe. Collaborating with our sibling project 3W3W, we
            strive to carve a unique path. Our aspiration is to instill the
            realization that the blockchain world should not be devoid of
            innovation. Turning the existing world upside down will create a
            brand new world. WEWE symbolizes each one of us - you, me, and all
            the like-minded individuals within the Web3 world. Together as WEWE,
            we aim to enhance the Web3 environment - WEWE are making Web3 a
            better place, WE are making Web3 a better place.
          </div>
          <div className="banner-logo-container">
            <img src={bannerBg2} alt="" />
          </div>
        </div>
      </div>
      <div className="mb-four-banner" id="tokenomics">
        <div className="banner-title">
          <img src={bannerTitle3} alt="Tokenomics" />
        </div>
        <div className="mb-banner-container">
          <div className="mb-banner-first">
            <div className="mb-banner-des">
              <p>
                12 billion WEWE tokens will be minted in total. They serve a
                form of our cultural expression, a reward for our passionate
                community and a motivation for our relentless contributors.
              </p>
              <p>WEWE tokens will be distributed into 6 main pools:</p>
            </div>
            <img src={bannerChart} alt="chart" />
          </div>
          <div className="mb-banner-second">
            <div className="banner-chart-container">
              <div className="banner-chart-item">
                <span className="serial-number">1</span>
                <span className="chart-text">
                  Ecosystem 35% reserved to build the ecosystem consistently
                </span>
              </div>
              <div className="banner-chart-item">
                <span className="serial-number">2</span>
                <span className="chart-text">
                  Contributors 30% for the partners that build with us 
                </span>
              </div>
              <div className="banner-chart-item">
                <span className="serial-number">3</span>
                <span className="chart-text">
                  Community airdrop 15% will be distributed to active community
                  participants 
                </span>
              </div>
              <div className="banner-chart-item">
                <span className="serial-number">4</span>
                <span className="chart-text">
                  Private sale 10% distributed to external investors and holders
                  via private sale 
                </span>
              </div>
              <div className="banner-chart-item">
                <span className="serial-number">5</span>
                <span className="chart-text">
                  Advisory 5% for the counseling we need to pull through 
                </span>
              </div>
              <div className="banner-chart-item">
                <span className="serial-number">6</span>
                <span className="chart-text">
                  Cex/Dex liquidity 5% for the initial liquidity of exchange
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="five-banner">
        <div className="banner-title">
          <img src={bannerTitle4} alt="WEWE" />
        </div>
        <div className="banner-link">
          <div className="banner-link-item">
            <a
              href="https://x.com/3w3w_realmeme?s=20"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={telegramIcon} className="link-icon" alt="Telegram" />
            </a>
          </div>
          <div className="banner-link-item">
            <a
              href="https://twitter.com/3w3w_meme"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterIcon} className="link-icon" alt="Twitter" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileContainer;
